<template>
  <div>
    <h3>{{ title }}</h3>
    <p>{{ subtitle }}</p>

    <b-spinner
      v-if="isLoading || subscriptionCheckInterval != null"
      variant="primary"
    />
    <div v-else>
      <div>
        <!--        <b-dropdown-->
        <!--          right-->
        <!--          class="d-block d-md-none text-center"-->
        <!--          variant="outline-primary"-->
        <!--          :text="$tc('pricing.x_months', months)"-->
        <!--        >-->
        <!--          <b-dropdown-item-->
        <!--            @click="months = 1"-->
        <!--          >-->
        <!--            {{ $tc('pricing.x_months', 1) }}-->
        <!--          </b-dropdown-item>-->
        <!--          <b-dropdown-item-->
        <!--            @click="months = 3"-->
        <!--          >-->
        <!--            {{ $tc('pricing.x_months', 3) }}-->
        <!--          </b-dropdown-item>-->
        <!--          <b-dropdown-item-->
        <!--            @click="months = 6"-->
        <!--          >-->
        <!--            {{ $tc('pricing.x_months', 6) }}-->
        <!--          </b-dropdown-item>-->
        <!--        </b-dropdown>-->
        <!--        <b-button-group-->
        <!--          size="lg"-->
        <!--          class="text-center d-none d-md-block mt-2"-->
        <!--        >-->
        <!--          <b-button-->
        <!--            :variant="months === 1 ? 'primary' : 'outline-primary'"-->
        <!--            @click="months = 1"-->
        <!--          >-->
        <!--            1 month-->
        <!--          </b-button>-->
        <!--          <b-button-->
        <!--            :variant="months === 3 ? 'primary' : 'outline-primary'"-->
        <!--            @click="months = 3"-->
        <!--          >-->
        <!--            3 months-->
        <!--          </b-button>-->
        <!--          <b-button-->
        <!--            :variant="months === 6 ? 'primary' : 'outline-primary'"-->
        <!--            @click="months = 6"-->
        <!--          >-->
        <!--            6 months-->
        <!--          </b-button>-->
        <!--        </b-button-group>-->

        <div class="my-1 mx-1 row">
          <div
            v-for="(plan, index) in pricingPlans"
            :key="`plan${index}`"
            class="col-lg-4 col-md-6 col-sm-12 p-1"
          >
            <pricing-plan
              :plan="plan"
              :months="months"
              class="w-100"
              @plan-selected="planSelected($event)"
            />
          </div>
        </div>

        <router-link class="d-table m-auto" to="/home">
          <b>{{ $t("pricing.subscribeLater") }}</b>
        </router-link>
      </div>
    </div>

    <b-modal
      id="killswitch-modal"
      centered
      ok-only
      :ok-title="$t('generic.close')"
      ok-variant="primary-outline"
      header-class="bg-white"
      size="lg"
    >
      <b-card-body class="p-4">
        <h1 class="mb-3 text-primary text-center">
          {{ $t("killSwitch.title") }}
        </h1>
        <h5>
          {{ $t("killSwitch.message") }}
        </h5>
        <b-alert show variant="secondary" class="border p-1 my-1">
          <p>{{ killSwitchText }}</p>
        </b-alert>
        <h5 class="text-center">
          {{ $t("killSwitch.closing") }}
        </h5>
      </b-card-body>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BSpinner,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BModal,
  BCardBody,
  BAlert,
} from "bootstrap-vue";
import PricingPlan from "@/components/PricingPlan.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import router from "@/router";

const currentOfferings = [
  {
    name: "Counselor Connect",
    isPopular: false,
    price: {
      1: {
        price: 99,
        priceId: process.env.STRIPE_PRODUCT_B2C1_1M,
      },
      3: {
        price: 94,
        priceId: process.env.STRIPE_PRODUCT_B2C1_3M,
      },
      6: {
        price: 89,
        priceId: process.env.STRIPE_PRODUCT_B2C1_6M,
      },
    },
    features: [
      "custom counselor assignment",
      "online scheduling & calendar",
      "real-time counselor chat Mon-Fri 8am-midnight PST",
      "access to social support groups",
      "personalized care pathway & homework",
      "counselor support: weekly sessions (4/month)",
    ],
  },
  {
    name: "Care Team Connect",
    isPopular: true,
    price: {
      1: {
        price: 199,
        priceId: process.env.STRIPE_PRODUCT_B2C2_1M,
      },
      3: {
        price: 189,
        priceId: process.env.STRIPE_PRODUCT_B2C2_3M,
      },
      6: {
        price: 179,
        priceId: process.env.STRIPE_PRODUCT_B2C2_6M,
      },
    },
    features: [
      "counselor & clinical therapist team",
      "online scheduling & calendar",
      "real-time counselor chat Mon-Fri 8am-midnight PST",
      "access to social support groups",
      "custom treatment plan",
      "clinical diagnosis",
      "planned exercises & assessments",
      "counselor support: 2 sessions/month",
      "therapist support: 2 sessions/month",
    ],
  },
  {
    name: "Therapist Connect",
    isPopular: false,
    price: {
      1: {
        price: 299,
        priceId: process.env.STRIPE_PRODUCT_B2C3_1M,
      },
      3: {
        price: 289,
        priceId: process.env.STRIPE_PRODUCT_B2C3_3M,
      },
      6: {
        price: 269,
        priceId: process.env.STRIPE_PRODUCT_B2C3_6M,
      },
    },
    features: [
      "focused clinical therapist sessions",
      "online scheduling & calendar",
      "real-time counselor chat Mon-Fri 8am-midnight PST",
      "access to social support groups",
      "custom treatment plan",
      "clinical diagnosis",
      "counselor support: sessions as directed by therapist",
      "therapist support: 4 sessions/month",
    ],
  },
];


export default {
  name: "Subscribe",
  components: {
    PricingPlan,
    BButton,
    BSpinner,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BModal,
    BCardBody,
    BAlert,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      isLoading: false,
      showPricing: false,
      months: 1,
      error: null,
      subscriptionCheckCount: 0,
      subscriptionCheckInterval: null,
      pricingPlans: [],
      killSwitchText: "",
    };
  },
  computed: {
    title() {
      return this.$t("pricing.title");
    },
    subtitle() {
      return this.$t("pricing.subtitle");
    },
  },
  watch: {
    subscriptionCheckCount(newVal) {
      if (newVal > 10) {
        this.clearSubscriptionCheck();
      }
    },
  },
  mounted() {
    // Fetch pricing plans
    this.fetchPricingPlans();

    // Check if payment complete
    const params = new URLSearchParams(window.location.search);
    if (params.get("paymentComplete")) {
      this.subscriptionCheckInterval = setInterval(() => {
        this.checkSubscription();
      }, 3000);
    }
  },
  methods: {
    fetchPricingPlans() {
      this.isLoading = true;

      this.$http
        .get("/subscriptions")
        .then((response) => {
          this.pricingPlans = response.data;
        })
        .catch(() => {
          this.pricingPlans = currentOfferings;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    checkSubscription() {
      this.$http
        .get("/user/dashboard")
        .then((response) => {
          if (response.data.validSubscription) {
            this.clearSubscriptionCheck();
            this.$emit("subscription-complete");
          }
        })
        .finally(() => {
          this.subscriptionCheckCount += 1;
        });
    },
    clearSubscriptionCheck() {
      clearInterval(this.subscriptionCheckInterval);
      this.subscriptionCheckInterval = null;
      this.subscriptionCheckCount = 0;
    },
    planSelected(plan) {
      this.isLoading = true;
      const route = router.resolve({ name: "subscription-success" });
      const successURL = new URL(route.href, window.location.origin).href;
      this.$http
        .post("/user/stripe/create-session", {
          priceId: plan.price[this.months].priceId,
          successUrl: `${successURL}?session_id={CHECKOUT_SESSION_ID}`,
          cancelUrl: window.location.href,
        })
        .then((response) => {

          if (response.data.url != null) {
            window.location.href = response.data.url;
          } else {
            this.error = this.$t("pricing.unableToCollectPayment");
          }
        })
        .catch((error) => {
          if (error.response.status === 423) {
            this.killSwitchText = error.response.data.message;
            this.$bvModal.show("killswitch-modal");
          } else {
            this.error = this.$t("pricing.unableToCollectPayment");
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>