<template>
  <div class="w-100 mh-80">
    <b-alert
      v-if="apiError != null"
      variant="danger"
      show
    >
      <div class="alert-body">
        {{ apiError }}
      </div>
    </b-alert>
    <div
      v-else-if="journey != null"
    >
      <form-wizard
        ref="wizard"
        :title="null"
        :subtitle="null"
        color="#497CA1"
        shape="square"
        :next-button-text="null"
        :back-button-text="null"
        :finish-button-text="null"
        :start-index="index"
        @on-complete="onComplete"
      >
        <tab-content
          v-for="(tab, i) in journey.activities"
          :key="i"
          :title="tab.renderConfig.displayText"
          :lazy="true"
        >
          <assessment
            v-if="tab.activityType === 'assessment'"
            :assessment-id="tab.activityMetaData"
            :allow-finish-later="journey.isOptional"
            @assessment-complete="nextTab"
          />
          <subscribe
            v-if="tab.activityType === 'subscribe'"
            @subscription-complete="subscriptionComplete"
          />
          <care-pairing
            v-else-if="tab.activityType === 'care-pair'"
            @pair-sub-complete="nextTab"
          />
        </tab-content>

        <template slot="footer">
          <span />
        </template>
      </form-wizard>
    </div>
    <card-spinner v-else />
  </div>
</template>

<script>
import {
  BAlert,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Assessment from '@/components/assessments/Assessment.vue'
import CarePairing from '@/components/CarePairing.vue'
import Subscribe from '@/components/Subscribe.vue'
import CardSpinner from '@/components/CardSpinner.vue'

export default {
  name: 'MWizard',
  components: {
    CardSpinner,
    Subscribe,
    CarePairing,
    Assessment,
    BAlert,
    FormWizard,
    TabContent,
  },
  data() {
    return {
      wizardId: this.$route.params.wizardId,
      journey: null,
      index: 0,
      apiError: null,
    }
  },
  watch: {
    journey(newVal) {
      const { activities } = newVal
      const index = activities.findIndex(a => a.activityStatus === 'Assigned')
      this.index = index > -1 ? index : 0
    },
  },
  mounted() {
    this.$http.get(`/user/activities/${this.wizardId}`)
      .then(response => {
        this.journey = response.data
        if (response.data.activityStatus === 'Completed') {
          this.onComplete()
        }
      })
      .catch(() => {
        this.apiError = this.$t('generic.apiError')
      })
  },
  methods: {
    onComplete() {
      this.$router.replace({
        name: 'home',
        params: {
          forceActivityRefresh: true,
        },
      })
    },
    subscriptionComplete() {
      if (this.journey.activities[this.index].activityType === 'subscribe') {
        this.nextTab()
      }
    },
    nextTab() {
      this.$refs.wizard.nextTab()
    },
  },
}
</script>

<style scoped>
@media (min-width: 992px) {
  .mh-80 {
    min-height: 80vh;
  }
}
</style>

<style lang="scss">
@import './src/@core/scss/vue/libs/vue-wizard.scss';
@import './src/@core/scss/vue/libs/vue-select.scss';
</style>
