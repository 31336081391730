<template>
  <div>
    <h3>{{ title }}</h3>
    <p>{{ subtitle }}</p>

    <b-spinner
      v-if="isLoading"
      variant="primary"
    />
    <div v-else>
      <div class="my-3 overflow-auto d-flex mw-100">
        <counselor
          v-for="(counselor, index) in counselors"
          :key="`counselor${index}`"
          :counselor="counselor"
          :is-selected="selectedCounselor != null && counselor.userId === selectedCounselor.userId"
          class="m-1 flex-shrink-0"
          @counselor-selected="counselorSelected($event)"
        />
      </div>

      <div class="w-100 d-inline-flex justify-content-between">
        <b-button
          variant="outline-primary"
          @click="decideLater"
        >
          {{ $t('generic.decideLater') }}
        </b-button>
        <b-button
          variant="primary"
          :disabled="selectedCounselor == null"
          @click="actionPressed"
        >
          {{ $t('counselors.subscribedCallToAction') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import Counselor from '@/components/Counselor.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CarePairing',
  components: {
    BButton,
    BSpinner,
    Counselor,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      isLoading: false,
      counselors: null,
      selectedCounselor: null,
      error: null,
    }
  },
  computed: {
    title() {
      return this.$t('counselors.selectionTitle')
    },
    subtitle() {
      return this.$t('counselors.selectionSubtitle')
    },
  },
  mounted() {
    this.fetchCounselors()
  },
  methods: {
    actionPressed() {
      if (this.selectedCounselor != null) {
        this.isLoading = true

        this.$http.post('/user/care-pairing', {
          careStaffType: 'counselor',
          selectedCareStaffId: this.selectedCounselor.userId,
        })
          .then(response => {
            if (response.data.success) {
              this.$emit('pair-sub-complete')
            } else {
              this.error = this.$t('pricing.unableToSelectCounselor')
            }
          })
          .catch(() => {
            this.error = this.$t('pricing.unableToSelectCounselor')
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('counselors.missingSelection.title'),
            icon: 'AlertCircleIcon',
            text: this.$t('counselors.missingSelection.message'),
            variant: 'danger',
          },
        })
      }
    },
    fetchCounselors() {
      this.error = null
      this.isLoading = true

      this.$http.get('/user/care-pairing')
        .then(response => {
          this.counselors = response.data.matchResults.potentialMatches
        })
        .catch(() => {
          this.error = this.$t('generic.apiError')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    counselorSelected(counselor) {
      this.selectedCounselor = counselor
    },
    decideLater() {
      this.$router.replace({
        name: 'home',
        params: {
          forceActivityRefresh: true,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
