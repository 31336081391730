<template>
  <div class="shadow h-100 rounded">
    <b-card
      class="popular pricing-card h-100"
      align="center"
      no-body
    >
      <b-card-body class="justify-content-between flex-column d-flex">
        <div>
          <div
            :class="plan.isPopular ? 'visible' : 'invisible'"
            class="text-right"
            style="height: 32px;"
          >
            <b-badge
              variant="success"
              class="badge-glow"
            >
              {{ $t('pricing.mostPopular') }}
            </b-badge>
          </div>

          <h2>{{ plan.name }}</h2>

          <div class="annual-plan">
            <div class="plan-price mt-2">
              <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
              <span class="pricing-basic-value font-weight-bolder text-primary">
                {{ +plan.price[months].price.toLocaleString() }}
              </span>
              <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/ monthly</sub>
            </div>
            <small
              v-if="months > 1"
              class="annual-pricing text-muted"
            >
              ${{ (+plan.price[months].price * months).toLocaleString() }} charged every {{ months }} months
            </small>
          </div>

          <b-list-group class="list-group-circle text-left">
            <b-list-group-item
              v-for="(feature, index) in plan.features"
              :key="index"
            >
              {{ feature }}
            </b-list-group-item>
          </b-list-group>
        </div>

        <!-- buttons -->
        <b-button
          block
          class="mt-2"
          variant="primary"
          @click="$emit('plan-selected', plan)"
        >
          Subscribe
        </b-button>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BListGroup, BListGroupItem, BButton, BBadge,
} from 'bootstrap-vue'

export default {
  name: 'PricingPlan',
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    BCard,
    BCardBody,
    BBadge,
  },
  props: {
    plan: {
      type: Object,
      required: true,
    },
    months: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import './src/@core/scss/vue/pages/page-pricing.scss';
</style>
